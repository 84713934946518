
import { Component, Vue } from 'vue-property-decorator'
import { ResultInfo } from '@/types/worker'

@Component({
  name: 'Event'
})
export default class List extends Vue {
private searchInfo = {
  projectName: '',
  startDate: '',
  endDate: ''
}

private loading = false
private tableData: ResultInfo[]= []
page = 1
size = 9
total = 0
private dateRange: Array<string> = []

created () {
  this.getData()
}

// 获取工程事件列表
getData () {
  this.loading = true
  if (this.dateRange) {
    this.searchInfo.startDate = this.dateRange[0] || ''
    this.searchInfo.endDate = this.dateRange[1] || ''
  } else {
    this.searchInfo.startDate = ''
    this.searchInfo.endDate = ''
  }
  this.$axios.get<{ total: number; list: Array<ResultInfo> }>(this.$apis.project.selectMplAttendanceList, {
    ...this.searchInfo,
    page: this.page,
    size: this.size
  }).then(res => {
    this.total = res.total || 0
    this.tableData = res.list || []
  }).finally(() => {
    this.loading = false
  })
}

onSearch () {
  this.page = 1
  this.getData()
}
}
